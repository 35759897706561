<template>
  <MainCard :data-header="dataHeader">
    <div class="uk-margin-top uk-margin-bottom">
      <div class="uk-card uk-card-default uk-padding-small kandang-card">
        <loading-card v-if="isLoading" />
        <template>
          <container :contract-price="contractPrice" />
          <div id="container-modal-edit-contract-price" />
          <modal-out-confirm />
          <modal-save-confirm />
        </template>
      </div>
    </div>
  </MainCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import LoadingCard from '@/components/globals/loading'
import MainCard from '@/components/globals/card'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    MainCard,
    LoadingCard
  },
  data() {
    return {
      isLoading: true,
      dataHeader: {
        title: 'Tambah Harga Kontrak',
        isBreadcrumb: true,
        breadcrumb: [
          {
            link: '/admin/jenis-ovk',
            title: 'Jenis OVK'
          },
          {
            link: null,
            title: 'Tambah Data'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      contractPrice: 'contractPrice/priceDetail'
    })
  },
  async mounted() {
    await this.getPriceDetail(this.$route.params.id)
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      getPriceDetail: 'contractPrice/getPriceDetail'
    })
  }
}
</script>
